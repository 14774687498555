@import (reference) "bower_components/bootstrap/less/bootstrap";

/*
 * Datepicker element
 */

.datepicker.dropdown-menu {
  width: 250px;
  height: 270px;
  button {
    outline: none;
    border: 0px;
  }
  tbody {
    height: 180px;
  }
  tbody button {
    padding: 6px;
  }
  &.datepicker-mode-1, &.datepicker-mode-2 {
    tbody button {
      height: 65px;
    }
  }
}
