@import (reference) "bower_components/bootstrap/less/bootstrap";

/*
 * Timepicker element
 */

.timepicker.dropdown-menu {
  padding: 0 4px;
  button {
    outline: none;
    border: 0px;
  }
  tbody button {
    padding: 6px;
  }
}
