@import (reference) "bower_components/bootstrap/less/bootstrap";

/*
 * Fancy tooltips
 *
 */

.tooltip {
  &.tooltip-info {
    &.top .tooltip-arrow, &.top-left .tooltip-arrow, &.top-right .tooltip-arrow {
      border-top-color: @alert-info-bg;
    }
    &.right .tooltip-arrow {
      border-right-color: @alert-info-bg;
    }
    &.bottom .tooltip-arrow, &.bottom-left .tooltip-arrow, &.bottom-right .tooltip-arrow {
      border-bottom-color: @alert-info-bg;
    }
    &.left .tooltip-arrow {
      border-left-color: @alert-info-bg;
    }
    .tooltip-inner {
      .alert-variant(@alert-info-bg; @alert-info-border; @alert-info-text);
    }
  }
  &.tooltip-success {
    &.top .tooltip-arrow, &.top-left .tooltip-arrow, &.top-right .tooltip-arrow {
      border-top-color: @alert-success-bg;
    }
    &.right .tooltip-arrow {
      border-right-color: @alert-success-bg;
    }
    &.bottom .tooltip-arrow, &.bottom-left .tooltip-arrow, &.bottom-right .tooltip-arrow {
      border-bottom-color: @alert-success-bg;
    }
    &.left .tooltip-arrow {
      border-left-color: @alert-success-bg;
    }
    .tooltip-inner {
      .alert-variant(@alert-success-bg; @alert-success-border; @alert-success-text);
    }
  }
  &.tooltip-danger {
    &.top .tooltip-arrow, &.top-left .tooltip-arrow, &.top-right .tooltip-arrow {
      border-top-color: @alert-danger-bg;
    }
    &.right .tooltip-arrow {
      border-right-color: @alert-danger-bg;
    }
    &.bottom .tooltip-arrow, &.bottom-left .tooltip-arrow, &.bottom-right .tooltip-arrow {
      border-bottom-color: @alert-danger-bg;
    }
    &.left .tooltip-arrow {
      border-left-color: @alert-danger-bg;
    }
    .tooltip-inner {
      .alert-variant(@alert-danger-bg; @alert-danger-border; @alert-danger-text);
    }
  }
}

/*
 * Tooltip corner placement
 *
 * Inherit exotic positionning from basic ones & fix arrow placement
 */

.tooltip {
  &.top-left {
    .tooltip.top;
    .tooltip-arrow {
      .tooltip.top .tooltip-arrow;
      left: 10%;
    }
  }
  &.top-right {
    .tooltip.top;
    .tooltip-arrow {
      .tooltip.top .tooltip-arrow;
      left: 90%;
    }
  }
  &.bottom-left {
    .tooltip.bottom;
    .tooltip-arrow {
      .tooltip.bottom .tooltip-arrow;
      left: 10%;
    }
  }
  &.bottom-right {
    .tooltip.bottom;
    .tooltip-arrow {
      .tooltip.bottom .tooltip-arrow;
      left: 90%;
    }
  }
}
