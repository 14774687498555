@import (reference) "bower_components/bootstrap/less/bootstrap";

/*
 * Callouts
 *
 * Not quite alerts, but custom and helpful notes for folks reading the docs.
 * Requires a base and modifier class.
 */

.callout {

  margin: 20px 0;
  padding: 20px;
  border-left: 3px solid #eee;

  h4 {
    margin-top: 0;
    margin-bottom: 5px;
  }

  p:last-child {
    margin-bottom: 0;

  }

}

/* Variations */
.callout-danger {
  border-color: #eed3d7;
  background-color: #fdf7f7;
}
.callout-danger h4 {
  color: #b94a48;
}
.callout-warning {
  border-color: #faebcc;
  background-color: #faf8f0;
}
.callout-warning h4 {
  color: #8a6d3b;
}
.callout-info {
  border-color: #bce8f1;
  background-color: #f4f8fa;
}
.callout-info h4 {
  color: #34789a;
}
