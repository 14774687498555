.thumbnail > img,
.thumbnail a > img,
.carousel-inner > .item > img,
.carousel-inner > .item > a > img {
  display: block;
  max-width: 100%;
  height: auto;
}
.btn-group-lg > .btn {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px;
}
.btn-group-sm > .btn {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}
.btn-group-xs > .btn {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}
.dl-horizontal dd:before,
.dl-horizontal dd:after,
.container:before,
.container:after,
.container-fluid:before,
.container-fluid:after,
.row:before,
.row:after,
.form-horizontal .form-group:before,
.form-horizontal .form-group:after,
.btn-toolbar:before,
.btn-toolbar:after,
.btn-group-vertical > .btn-group:before,
.btn-group-vertical > .btn-group:after,
.nav:before,
.nav:after,
.navbar:before,
.navbar:after,
.navbar-header:before,
.navbar-header:after,
.navbar-collapse:before,
.navbar-collapse:after,
.pager:before,
.pager:after,
.panel-body:before,
.panel-body:after,
.modal-footer:before,
.modal-footer:after,
.aside .aside-dialog .aside-footer:before,
.aside .aside-dialog .aside-footer:after {
  content: " ";
  display: table;
}
.dl-horizontal dd:after,
.container:after,
.container-fluid:after,
.row:after,
.form-horizontal .form-group:after,
.btn-toolbar:after,
.btn-group-vertical > .btn-group:after,
.nav:after,
.navbar:after,
.navbar-header:after,
.navbar-collapse:after,
.pager:after,
.panel-body:after,
.modal-footer:after,
.aside .aside-dialog .aside-footer:after {
  clear: both;
}
/*
 * Aside element
 */
.aside {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 1049;
  overflow: auto;
  min-width: 320px;
  background: white;
}
.aside:focus {
  outline: none;
}
@media (max-width: 991px) {
  .aside {
    min-width: 240px;
  }
}
.aside.left {
  right: auto;
  left: 0;
}
.aside.right {
  right: 0;
  left: auto;
}
.aside .aside-dialog .aside-header {
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
  min-height: 16.42857143px;
  padding: 6px 15px;
  background: #337ab7;
  color: white;
}
.aside .aside-dialog .aside-header .close {
  margin-right: -8px;
  padding: 4px 8px;
  color: white;
  font-size: 25px;
  opacity: .8;
}
.aside .aside-dialog .aside-body {
  position: relative;
  padding: 15px;
}
.aside .aside-dialog .aside-footer {
  padding: 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
}
.aside .aside-dialog .aside-footer .btn + .btn {
  margin-left: 5px;
  margin-bottom: 0;
}
.aside .aside-dialog .aside-footer .btn-group .btn + .btn {
  margin-left: -1px;
}
.aside .aside-dialog .aside-footer .btn-block + .btn-block {
  margin-left: 0;
}
.aside-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000000;
}
.aside-backdrop.fade {
  opacity: 0;
  filter: alpha(opacity=0);
}
.aside-backdrop.in {
  opacity: 0.5;
  filter: alpha(opacity=50);
}
