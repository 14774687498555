@import (reference) "bower_components/bootstrap/less/bootstrap";

// Support center placement
.modal {
  &.center {
    .modal-dialog {
      position: fixed;
      top: 40%;
      left: 50%;
      min-width: 320px;
      max-width: 630px;
      width: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }
}
