.thumbnail > img,
.thumbnail a > img,
.carousel-inner > .item > img,
.carousel-inner > .item > a > img {
  display: block;
  max-width: 100%;
  height: auto;
}
.btn-group-lg > .btn {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px;
}
.btn-group-sm > .btn {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}
.btn-group-xs > .btn {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}
.dl-horizontal dd:before,
.dl-horizontal dd:after,
.container:before,
.container:after,
.container-fluid:before,
.container-fluid:after,
.row:before,
.row:after,
.form-horizontal .form-group:before,
.form-horizontal .form-group:after,
.btn-toolbar:before,
.btn-toolbar:after,
.btn-group-vertical > .btn-group:before,
.btn-group-vertical > .btn-group:after,
.nav:before,
.nav:after,
.navbar:before,
.navbar:after,
.navbar-header:before,
.navbar-header:after,
.navbar-collapse:before,
.navbar-collapse:after,
.pager:before,
.pager:after,
.panel-body:before,
.panel-body:after,
.modal-footer:before,
.modal-footer:after {
  content: " ";
  display: table;
}
.dl-horizontal dd:after,
.container:after,
.container-fluid:after,
.row:after,
.form-horizontal .form-group:after,
.btn-toolbar:after,
.btn-group-vertical > .btn-group:after,
.nav:after,
.navbar:after,
.navbar-header:after,
.navbar-collapse:after,
.pager:after,
.panel-body:after,
.modal-footer:after {
  clear: both;
}
/*
 * Fancy tooltips
 *
 */
.tooltip.tooltip-info.top .tooltip-arrow,
.tooltip.tooltip-info.top-left .tooltip-arrow,
.tooltip.tooltip-info.top-right .tooltip-arrow {
  border-top-color: #d9edf7;
}
.tooltip.tooltip-info.right .tooltip-arrow {
  border-right-color: #d9edf7;
}
.tooltip.tooltip-info.bottom .tooltip-arrow,
.tooltip.tooltip-info.bottom-left .tooltip-arrow,
.tooltip.tooltip-info.bottom-right .tooltip-arrow {
  border-bottom-color: #d9edf7;
}
.tooltip.tooltip-info.left .tooltip-arrow {
  border-left-color: #d9edf7;
}
.tooltip.tooltip-info .tooltip-inner {
  background-color: #d9edf7;
  border-color: #bce8f1;
  color: #31708f;
}
.tooltip.tooltip-info .tooltip-inner hr {
  border-top-color: #a6e1ec;
}
.tooltip.tooltip-info .tooltip-inner .alert-link {
  color: #245269;
}
.tooltip.tooltip-success.top .tooltip-arrow,
.tooltip.tooltip-success.top-left .tooltip-arrow,
.tooltip.tooltip-success.top-right .tooltip-arrow {
  border-top-color: #dff0d8;
}
.tooltip.tooltip-success.right .tooltip-arrow {
  border-right-color: #dff0d8;
}
.tooltip.tooltip-success.bottom .tooltip-arrow,
.tooltip.tooltip-success.bottom-left .tooltip-arrow,
.tooltip.tooltip-success.bottom-right .tooltip-arrow {
  border-bottom-color: #dff0d8;
}
.tooltip.tooltip-success.left .tooltip-arrow {
  border-left-color: #dff0d8;
}
.tooltip.tooltip-success .tooltip-inner {
  background-color: #dff0d8;
  border-color: #d6e9c6;
  color: #3c763d;
}
.tooltip.tooltip-success .tooltip-inner hr {
  border-top-color: #c9e2b3;
}
.tooltip.tooltip-success .tooltip-inner .alert-link {
  color: #2b542c;
}
.tooltip.tooltip-danger.top .tooltip-arrow,
.tooltip.tooltip-danger.top-left .tooltip-arrow,
.tooltip.tooltip-danger.top-right .tooltip-arrow {
  border-top-color: #f2dede;
}
.tooltip.tooltip-danger.right .tooltip-arrow {
  border-right-color: #f2dede;
}
.tooltip.tooltip-danger.bottom .tooltip-arrow,
.tooltip.tooltip-danger.bottom-left .tooltip-arrow,
.tooltip.tooltip-danger.bottom-right .tooltip-arrow {
  border-bottom-color: #f2dede;
}
.tooltip.tooltip-danger.left .tooltip-arrow {
  border-left-color: #f2dede;
}
.tooltip.tooltip-danger .tooltip-inner {
  background-color: #f2dede;
  border-color: #ebccd1;
  color: #a94442;
}
.tooltip.tooltip-danger .tooltip-inner hr {
  border-top-color: #e4b9c0;
}
.tooltip.tooltip-danger .tooltip-inner .alert-link {
  color: #843534;
}
/*
 * Tooltip corner placement
 *
 * Inherit exotic positionning from basic ones & fix arrow placement
 */
.tooltip.top-left {
  margin-top: -3px;
  padding: 5px 0;
}
.tooltip.top-left .tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000000;
  left: 10%;
}
.tooltip.top-right {
  margin-top: -3px;
  padding: 5px 0;
}
.tooltip.top-right .tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000000;
  left: 90%;
}
.tooltip.bottom-left {
  margin-top: 3px;
  padding: 5px 0;
}
.tooltip.bottom-left .tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000000;
  left: 10%;
}
.tooltip.bottom-right {
  margin-top: 3px;
  padding: 5px 0;
}
.tooltip.bottom-right .tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000000;
  left: 90%;
}
